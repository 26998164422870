<template>
    <div class="PayTemplateEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="PayTemplate" label-width="120px" size="small">
                <el-form-item label="名称">
                    <el-input v-model="PayTemplate.name" style="width: 200px" />
                </el-form-item>
                <el-form-item label="标志">
                    <el-input v-model="PayTemplate.flag" style="width: 200px" />
                </el-form-item>
                <el-form-item label="可支付(收银)">
                    <el-switch v-model="PayTemplate.canPay" />
                </el-form-item>
                <el-form-item label="可退款(收银)">
                    <el-switch v-model="PayTemplate.canRechargeRefund" />
                </el-form-item>
                <el-form-item label="可抹零(收银)">
                    <el-switch v-model="PayTemplate.canFloor" />
                </el-form-item>
                <el-form-item label="可组合支付(收银)">
                    <el-switch v-model="PayTemplate.canCombinationPay" />
                </el-form-item>
                <el-form-item label="可充值(会员)">
                    <el-switch v-model="PayTemplate.canRecharge" />
                </el-form-item>
                <el-form-item label="可退款(会员)">
                    <el-switch v-model="PayTemplate.canRefund" />
                </el-form-item>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleSave" size="small">保存</el-button>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'PayTemplateEdit',
    props: ['form'],
    data() {
        return {
            PayTemplate: {
                canRefund: this.form.canRefund,
                canPay: this.form.canPay,
                canFloor: this.form.canFloor,
                canCombinationPay: this.form.canCombinationPay,
                canRecharge: this.form.canRecharge,
                canRechargeRefund: this.form.canRechargeRefund,
                name: this.form.name,
                flag: this.form.flag,
                // canPay: on
                // canRefund: on
                // canFloor: on
                // canCombinationPay: on
                // canRecharge: on
                // canRechargeRefund: on
            },
            url: {
                batchSave: '/finance/pay/template/patch_c/',
            },
        };
    },
    mounted() {},
    methods: {
        handleSave() {
            const _this = this;
            const disable = _this.PayTemplate.name != '' && _this.PayTemplate.flag != '';
            if (!disable) {
                _this.$message.error('标志和名称不能为空');
                return;
            }
            UrlUtils.PatchRemote(this, this.url.batchSave + this.form.code, this.PayTemplate, null, () => {
                this.goBackAndReload();
                this.$message.success('保存成功');
            });
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
